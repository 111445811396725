<template>
  <div class="van-refresh-list credit-list-info">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="finishedText"
        @load="onLoad"
      >
        <div v-for="item in list" :key="item.id" class="credit-item">
          <van-cell title="资金来源" :value="utils.statusFormat(String(item.sourceFunds), 'FundsSource')" />
          <van-cell v-if="item.sourceFunds==='0'" title="授信机构" :value="utils.isEffectiveCommon(item.creditInstitutions)" />
          <van-cell v-if="item.sourceFunds==='1'" title="授信机构" :value="utils.isEffectiveCommon(item.creditInstitutions)" />
          <van-cell v-if="item.sourceFunds==='0'" title="分支机构" :value="utils.isEffectiveCommon(item.branch)" />
          <van-cell title="授信主体" :value="utils.isEffectiveCommon(item.creditSubject)" />
          <van-cell title="授信额度" :value="utils.moneyFormat(item.creditLine)" />
          <van-cell title="授信期限" :value="utils.isEffectiveCommon(item.creditTerm)" />
          <van-cell title="授信起始日" :value="utils.dateFormat(item.creditStartDate)" />
          <van-cell title="授信到期日" :value="utils.dateFormat(item.creditEndDate)" />
          <van-cell title="已使用额度" :value="utils.moneyFormat(item.amountUsed)" />
          <van-cell title="剩余额度" :value="utils.moneyFormat((item.creditLine || 0) - (item.amountUsed || 0))" />
          <van-cell>
            <van-button plain round size="small" type="info" native-type="button" @click="detail(item)">
              查看详情
            </van-button>
          </van-cell>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { Dialog, Cell, CellGroup, Search, List, Divider, Button, Toast, PullRefresh } from 'vant'
export default {
  name: 'CreditList',
  components: {
    [Dialog.Component.name]: Dialog.Component,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [List.name]: List,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Toast.name]: Toast,
    [PullRefresh.name]: PullRefresh
  },
  props: {
    sourceFunds: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      searchForm: {
        sourceFunds: '',
        pageNum: 1,
        pageSize: 10
      },
      loading: false,
      finished: false,
      refreshing: false,
      finishedText: '没有更多了',
      tableList: {
        list: []
      },
      list: []
    }
  },
  watch: {
    // sourceFunds: 'init'
  },
  created () {
  },
  methods: {
    init () {
      // this.searchForm.sourceFunds = this.sourceFunds
    },
    getList () {
      this.searchForm.sourceFunds = this.sourceFunds
      this.api.fundManagement.credit.listPage(this.searchForm).then(result => {
        this.tableList = result.data.value.finacyCreditPageInfo
        if (this.tableList.pages === 0 && this._.isEmpty(this.tableList.list)) {
          this.list = []
          this.finishedText = '暂无数据'
          this.finished = true
        } else {
          if (this.tableList.pageNum === 1) {
            this.list = result.data.value.finacyCreditPageInfo.list
          } else {
            this.list = this.list.concat(result.data.value.finacyCreditPageInfo.list)
          }
          this.loading = false
          if (this.tableList.pageNum < this.tableList.pages) {
            this.searchForm.pageNum += 1
          } else {
            this.finished = true
          }
        }
      }).catch(() => {
        this.finished = true
        this.refreshing = false
        this.finishedText = '暂无数据'
      }).finally(() => {
        this.refreshing = false
      })
    },
    onRefresh () {
      // 清空列表数据
      this.finished = false
      this.loading = true
      this.searchForm.pageNum = 1
      this.getList()
    },
    onLoad () {
      this.getList()
    },
    detail (row) {
      this.$router.push(
        {
          name: 'creditDetail',
          query:
          {
            id: row.id
          }
        }
      )
    }
  }
}
</script>

<style lang="less" scoped>
.credit-list-info {
  .van-list {
    padding-bottom: 60px;
  }
  .credit-item {
    border:1px solid #ccc;
    margin: 10px 5px;
    border-radius: 4px;
  }
}

</style>
