<template>
  <div class="van-main credit-list">
    <van-tabs v-model="active">
      <van-tab title="全部">
        <credit-list />
      </van-tab>
      <van-tab title="银行授信">
        <credit-list source-funds="0" />
      </van-tab>
      <van-tab title="内部授信">
        <credit-list source-funds="1" />
      </van-tab>
    </van-tabs>
    <back-button />
  </div>
</template>

<script>
import BackButton from '@/components/back-button'
import CreditList from '@/views/fund/credit/component/credit-list'
import { Dialog, Cell, CellGroup, Search, List, Divider, Button, Toast, PullRefresh, Tab, Tabs } from 'vant'
export default {
  components: {
    BackButton,
    CreditList,
    [Dialog.Component.name]: Dialog.Component,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [List.name]: List,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Toast.name]: Toast,
    [PullRefresh.name]: PullRefresh
  },
  data () {
    return {
      active: 0
    }
  },
  created () {
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
.credit-list {
  .van-tabs {
    margin-bottom: 50px;
  }
  .van-tabs .van-refresh-list {
    margin-top: 10px;
  }
}
</style>
