var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-refresh-list credit-list-info" },
    [
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                finished: _vm.finished,
                "finished-text": _vm.finishedText
              },
              on: { load: _vm.onLoad },
              model: {
                value: _vm.loading,
                callback: function($$v) {
                  _vm.loading = $$v
                },
                expression: "loading"
              }
            },
            _vm._l(_vm.list, function(item) {
              return _c(
                "div",
                { key: item.id, staticClass: "credit-item" },
                [
                  _c("van-cell", {
                    attrs: {
                      title: "资金来源",
                      value: _vm.utils.statusFormat(
                        String(item.sourceFunds),
                        "FundsSource"
                      )
                    }
                  }),
                  item.sourceFunds === "0"
                    ? _c("van-cell", {
                        attrs: {
                          title: "授信机构",
                          value: _vm.utils.isEffectiveCommon(
                            item.creditInstitutions
                          )
                        }
                      })
                    : _vm._e(),
                  item.sourceFunds === "1"
                    ? _c("van-cell", {
                        attrs: {
                          title: "授信机构",
                          value: _vm.utils.isEffectiveCommon(
                            item.creditInstitutions
                          )
                        }
                      })
                    : _vm._e(),
                  item.sourceFunds === "0"
                    ? _c("van-cell", {
                        attrs: {
                          title: "分支机构",
                          value: _vm.utils.isEffectiveCommon(item.branch)
                        }
                      })
                    : _vm._e(),
                  _c("van-cell", {
                    attrs: {
                      title: "授信主体",
                      value: _vm.utils.isEffectiveCommon(item.creditSubject)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "授信额度",
                      value: _vm.utils.moneyFormat(item.creditLine)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "授信期限",
                      value: _vm.utils.isEffectiveCommon(item.creditTerm)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "授信起始日",
                      value: _vm.utils.dateFormat(item.creditStartDate)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "授信到期日",
                      value: _vm.utils.dateFormat(item.creditEndDate)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "已使用额度",
                      value: _vm.utils.moneyFormat(item.amountUsed)
                    }
                  }),
                  _c("van-cell", {
                    attrs: {
                      title: "剩余额度",
                      value: _vm.utils.moneyFormat(
                        (item.creditLine || 0) - (item.amountUsed || 0)
                      )
                    }
                  }),
                  _c(
                    "van-cell",
                    [
                      _c(
                        "van-button",
                        {
                          attrs: {
                            plain: "",
                            round: "",
                            size: "small",
                            type: "info",
                            "native-type": "button"
                          },
                          on: {
                            click: function($event) {
                              return _vm.detail(item)
                            }
                          }
                        },
                        [_vm._v(" 查看详情 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }