var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "van-main credit-list" },
    [
      _c(
        "van-tabs",
        {
          model: {
            value: _vm.active,
            callback: function($$v) {
              _vm.active = $$v
            },
            expression: "active"
          }
        },
        [
          _c("van-tab", { attrs: { title: "全部" } }, [_c("credit-list")], 1),
          _c(
            "van-tab",
            { attrs: { title: "银行授信" } },
            [_c("credit-list", { attrs: { "source-funds": "0" } })],
            1
          ),
          _c(
            "van-tab",
            { attrs: { title: "内部授信" } },
            [_c("credit-list", { attrs: { "source-funds": "1" } })],
            1
          )
        ],
        1
      ),
      _c("back-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }